<script>
    import MyMarket from './MyMarket.svelte';
    export let myPurcahsehistoy;
    import { inertia } from '@inertiajs/svelte';
    import { onMount } from 'svelte';
    let open = false;
    $: filterOption = 'All';
    $: filteredData = [];

    function toggleDropdown() {
        open = !open;
    }

    function selectOption(id) {
        filterOption = id;
        open = false;
        if (id === 'Users') {
            filteredData = myPurcahsehistoy.filter((record) => record.post_id === null);
        } else if (id === 'Posts') {
            filteredData = myPurcahsehistoy.filter((record) => record.post_id !== null);
        } else {
            filteredData = myPurcahsehistoy;
        }
    }

    onMount(() => {
        filteredData = myPurcahsehistoy;
    });
</script>

<svelte:component this="{MyMarket}" />

<div class="offerScreen px-3">
    <div class="d-flex items-center justify-end gap-2 px-3">
        <label for="gender" class="m-0">Filter:</label>
        <div class="relative block text-left">
            <div>
                <button
                    type="button"
                    class="inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    aria-expanded="{open}"
                    aria-haspopup="true"
                    on:click="{toggleDropdown}"
                >
                    {filterOption}
                    <svg
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="ui-svg-inline ml-2-5"
                        ><path
                            d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"
                        ></path></svg
                    >
                </button>
            </div>
            <div
                class=" absolute left-0 z-10 mt-2 w-full overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-all duration-500"
                class:h-0="{!open}"
                class:h-auto="{open}"
                class:scale-50="{!open}"
                class:scale-100="{open}"
                class:opacity-0="{!open}"
                class:opacity-100="{open}"
            >
                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    {#if filterOption === 'Users' || filterOption === 'Posts'}
                        <a class="dropdown-elem" role="menuitem" on:click="{() => selectOption('All')}">All</a>
                    {/if}
                    {#if filterOption !== 'Users'}
                        <a class="dropdown-elem" role="menuitem" on:click="{() => selectOption('Users')}">Users</a>
                    {/if}
                    {#if filterOption !== 'Posts'}
                        <a class="dropdown-elem" role="menuitem" on:click="{() => selectOption('Posts')}">Posts</a>
                    {/if}
                </div>
            </div>
        </div>
    </div>
    <div class="table-wrapper px-3 pb-4">
        {#if filteredData.length > 0}
            <div>
                <div class="col d-flex align-items-center border-bottom py-3 font-bold">
                    <div class="col-1 text-truncate">Sr.</div>
                    {#if filterOption !== 'Posts'}
                        <div class="{filterOption !== 'All' ? 'col-4' : 'col-3'} text-truncate">User</div>
                    {/if}
                    {#if filterOption !== 'Users'}
                        <div class="{filterOption !== 'All' ? 'col-4' : 'col-3'} text-truncate">Post</div>
                    {/if}
                    <div class="col-2 text-truncate">Amount</div>
                    <div class="{filterOption !== 'All' ? 'col-3' : 'col-2'} text-truncate">transaction fee</div>
                    <div class="col-2 text-truncate">Action</div>
                </div>
            </div>
            <div>
                {#each filteredData as myOffering, index}
                    <div class="col d-flex align-items-center border-bottom py-3">
                        <div class="col-1 text-truncate">{index + 1}</div>

                        {#if filterOption !== 'Posts'}
                            {#if myOffering.user_id}
                                <div class="{filterOption !== 'All' ? 'col-4' : 'col-3'} text-truncate">
                                    <a href="/{myOffering.user.username}">{myOffering.user.name}</a>
                                </div>
                            {:else}
                                <div class="col-3 text-truncate">-</div>
                            {/if}
                        {/if}
                        {#if filterOption !== 'Users'}
                            {#if myOffering.post_id}
                                <div class="{filterOption !== 'All' ? 'col-4' : 'col-3'} text-truncate">
                                    <a href="/{myOffering.amount}">{myOffering.post.text}</a>
                                </div>
                            {:else}
                                <div class="col-3 text-truncate">-</div>
                            {/if}
                        {/if}
                        <div class="{filterOption !== 'All' ? 'col-3' : 'col-2'} text-truncate">{myOffering.fee}</div>
                        <div class="col-2 text-truncate">
                            <a class="btn btn-primary m-0" href="/markets" use:inertia>Invoice</a>
                        </div>
                    </div>
                {/each}
            </div>
        {:else}
            <p class="p-4 text-center">No Record Found</p>
        {/if}
    </div>
</div>
